<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.reserved_goods") }}
    </v-card-title>

    <v-card-text>
      <div class="mb-1 d-flex align-center">
        <div class="w-35per">{{ $t("labels.warehouse_1") }}:</div>
        <div class="w-65per font-weight-medium">{{ item.warehouse_code }}</div>
      </div>
      <div class="mb-1 d-flex align-center">
        <div class="w-35per">{{ $t("labels.barcode") }}:</div>
        <div class="w-65per font-weight-medium">
          {{ item.customer_goods_barcode }}
        </div>
      </div>
      <div class="mb-1 d-flex align-center">
        <div class="w-35per">{{ $t("labels.sku") }}:</div>
        <div class="w-65per font-weight-medium">{{ item.sku }}</div>
      </div>
      <div class="mb-1 d-flex align-center">
        <div class="w-35per">{{ $t("labels.name") }}:</div>
        <div class="w-65per font-weight-medium">{{ item.name }}</div>
      </div>
      <div class="mb-1 d-flex align-center">
        <div class="w-35per">{{ $t("labels.goods_description") }}:</div>
        <div class="w-65per font-weight-medium">{{ item.description }}</div>
      </div>
      <div class="mb-1 d-flex align-center">
        <div class="w-35per">{{ $t("labels.available_quantity") }}:</div>
        <div class="w-65per font-weight-medium success--text">
          {{ formatNumber(item.available) }}
        </div>
      </div>
      <div class="mb-3 d-flex align-center">
        <div class="w-35per">{{ $t("labels.reserved_sum_quantity") }}:</div>
        <div class="w-65per font-weight-medium error--text">
          {{ formatNumber(item.reserved) }}
        </div>
      </div>

      <div>
        <v-text-field
          v-model.number="quantity"
          class="c-input-xs mb-3"
          type="number"
          :label="$t('labels.quantity')"
          dense
          outlined
          clearable
          hide-details
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          v-model="note"
          class="c-input-xs mb-3"
          type="text"
          :label="$t('labels.note')"
          dense
          outlined
          clearable
          hide-details
        ></v-text-field>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="create">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "CreateReserved",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    quantity: null,
    note: null,
  }),
  mounted() {},
  methods: {
    async create() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/create-goods-reserved", {
          quantity: this.quantity,
          note: this.note,
          id_goods: this.item.id,
          id_warehouse: this.item.id_warehouse,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.reserved_goods_success"));
        this.cancel();
        this.$emit("refreshData");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
